import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "c99e2b5b-ff18-4537-9fb1-23abdc816845"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderC99e2b5bFf1845379fb123abdc816845(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderC99e2b5bFf1845379fb123abdc816845 site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTop75b27ba049b842b49e6926af5079bcce" {...props} />}
      </div>
      {props["hero"] || <HomePageHero3809735cF32949b8Ab327266eb10a78b parentTag="Hero20681489139841448389086b3cf3700b" {...props} />}
    </div>
  );
}

// id: "93d89761-fd2c-4f67-ae40-0ae949137ee6"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage93d89761Fd2c4f67Ae400ae949137ee6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage93d89761Fd2c4f67Ae400ae949137ee6 page`}>
      {props["header"] || <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderAb6b2a3c1439457aBd3bB57171902c1d" title="About Us" {...props} />}
      {props["body"] || <MarketingBody01d485a44c6a4ae7Bcf42b842f411e59 parentTag="BodyE06d6f13C7bf429aB0fc09e1f5815663" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterFce22f9734164ba494e86508babea0dd" {...props} />}
    </div>
  );
}

// id: "fb9d6a18-4a1a-47c2-95de-285b3e110a34"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteFb9d6a184a1a47c295de285b3e110a34(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteFb9d6a184a1a47c295de285b3e110a34`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "07d40535-c3f4-49d5-8a3a-481db3fd6e78"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage07d40535C3f449d58a3a481db3fd6e78(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage07d40535C3f449d58a3a481db3fd6e78`}>
      {props["header"] || <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header59a9b41232c848fd9d456ebcced992e2" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterDd983a22077f4e7b9e6243be3a38ccbf" {...props} />}
    </div>
  );
}

// id: "6d09aaaa-4682-452b-86f9-24c242b1612c"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "896e73a1-8dfa-48ea-9a9e-3c3f2f333c83"
export function Hero6d09aaaa4682452b86f924c242b1612c(props: any) {
  return (
    <SecondaryMarketingHero862917f319a14d9eA4624c47415fa3d6 parentTag="Hero6d09aaaa4682452b86f924c242b1612c" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "b5a623c4-d82c-46d5-bec4-743dcf637dfc"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function SocialLinksB5a623c4D82c46d5Bec4743dcf637dfc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksB5a623c4D82c46d5Bec4743dcf637dfc flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "5c37a761-bc5f-4525-ab5a-aa561fab43a1"
// title: ""
// type: :html
// key: "logo"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function Logo5c37a761Bc5f4525Ab5aAa561fab43a1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-3/logo-wide-vector.svg" alt="SP Salem logo" className={`${parentTag || ""} Logo5c37a761Bc5f4525Ab5aAa561fab43a1 logo`} />
  );
}

// id: "ed0a097a-564d-4f48-8711-50f43d1511e3"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesEd0a097a564d4f48871150f43d1511e3(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesEd0a097a564d4f48871150f43d1511e3`}>
      {props.children}
    </div>
  );
}

// id: "ebabc526-d242-41e3-bf97-fd7f1f9ac5ef"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderEbabc526D24241e3Bf97Fd7f1f9ac5ef(props: any) {
  return (
    <ShortHeaderB46d920427b04de481ebEd7bd6620dd2 parentTag="DefaultHeaderEbabc526D24241e3Bf97Fd7f1f9ac5ef" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "c5b50c7c-fd8f-44cc-8c5f-9728320ada40"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterC5b50c7cFd8f44cc8c5f9728320ada40(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterC5b50c7cFd8f44cc8c5f9728320ada40" {...props} />
  );
}

// id: "03e1bb78-656f-4c0e-aa47-4393738a0548"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo03e1bb78656f4c0eAa474393738a0548(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-3/logo-wide-vector.svg" className={`${parentTag || ""} DefaultLogo03e1bb78656f4c0eAa474393738a0548 logo`} alt="logo" />
  );
}

// id: "60d4b1c0-e46b-4f82-b758-0d4bf9d01a90"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90`}>
      {props["header"] || <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderC398024cD4cf40df9f70D349866d07f3" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterFd987857A33a46acB69a1296f0cf0bb8" {...props} />}
    </div>
  );
}

// id: "2e9c4620-bef6-40ab-94bd-ce53c539c986"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages2e9c4620Bef640ab94bdCe53c539c986(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages2e9c4620Bef640ab94bdCe53c539c986`}>
      {props["header"] || <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header729b17da9f5341658c5dC78e1cd35ef8" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav2f225de3D6be47a1A53c97bb3c97ee60", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterC29b44bb6a13408bA7361c5f86a1149d" {...props} />}
    </div>
  );
}

// id: "659f1fca-0286-40c7-a44d-c57baa9ab102"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo13def918A3d0407bAf817d417ae76d0d", parentTag)} label={<DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="Label8b58be987fab46edB2283deadca33228" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation4f4e1138E1c245309987D0bd2a2c16b8", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "e0a3de4a-84f1-416d-9326-5808271b64d8"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "b46d9204-27b0-4de4-81eb-ed7bd6620dd2"
export function LinkedLogoE0a3de4a84f1416d93265808271b64d8(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoE0a3de4a84f1416d93265808271b64d8", parentTag)} label={<DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="LabelE7432bbf0d7347e3B30a3e22e3252e70" alt="logo" />} {...props} />
  );
}

// id: "951f1424-4ee3-4ae8-96ed-02b10daf613a"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "d6ce2573-3d31-465b-98e7-86ff0bc26e7d"
export const ButtonClass951f14244ee34ae896ed02b10daf613a = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "6bf5de4a-bb7c-4278-8f68-ca8943251a4f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "71ac9718-62f2-47eb-9c33-e68df9eb19a7"
export function Header6bf5de4aBb7c42788f68Ca8943251a4f(props: any) {
  return (
    <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header6bf5de4aBb7c42788f68Ca8943251a4f" {...props} />
  );
}

// id: "44f38bb2-c343-44f2-b426-087b6798ca78"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "572736ea-54f5-4cac-b2ee-7168b785432a"
export function Header44f38bb2C34344f2B426087b6798ca78(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header44f38bb2C34344f2B426087b6798ca78" title="Login" {...props} />
  );
}

// id: "97a19c38-5836-4028-a1d6-7c6138ee951a"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "b46d9204-27b0-4de4-81eb-ed7bd6620dd2"
export const BackgroundImage97a19c3858364028A1d67c6138ee951a = "https://mp1md-pub.s3.amazonaws.com/orgs/sp-salem/city-salem-background.image.jpg";

// id: "8ad452bd-655a-40d2-a2cc-3c66acd665a5"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
export function Body8ad452bd655a40d2A2cc3c66acd665a5(props: any) {
  return (
    <MarketingBody01d485a44c6a4ae7Bcf42b842f411e59 parentTag="Body8ad452bd655a40d2A2cc3c66acd665a5" postSlug="home-page-posts2" {...props} />
  );
}

// id: "8d2ecd3d-f358-4ccc-b711-1da6e63bdcf0"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "01d485a4-4c6a-4ae7-bcf4-2b842f411e59"
export function MarketingCards8d2ecd3dF3584cccB7111da6e63bdcf0(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards8d2ecd3dF3584cccB7111da6e63bdcf0", parentTag)} {...props} />
  );
}

// id: "60c625f0-4ee5-44a3-bad8-a4fe9f1721cd"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd(props: any) {
  return (
    <ShortHeaderB46d920427b04de481ebEd7bd6620dd2 parentTag="MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd" header-top={<DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTop5f3146efC98547fe8bd0Dbc687db3169" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "28d90ba7-bea4-4ae6-b6a2-89c6017d6b37"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlug28d90ba7Bea44ae6B6a289c6017d6b37 = "about-us-posts";

// id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("LinksE3a528025fac4e65834eBfa18ac8c950", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo5c37a761Bc5f4525Ab5aAa561fab43a1 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright003a08a856c2466d9c91B918e9e9001b", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow484dff563f1a4d33A6a75242df08d559", parentTag)} {...props} />}
    </div>
  );
}

// id: "7fc18d0c-536b-4a8b-89c3-958e97094976"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label7fc18d0c536b4a8b89c3958e97094976(props: any) {
  return (
    <DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="Label7fc18d0c536b4a8b89c3958e97094976" {...props} />
  );
}

// id: "1888364a-9101-4c90-b2c5-c905d1b1e848"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage1888364a91014c90B2c5C905d1b1e848(props: any) {
  return (
    <SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90 parentTag="JoinUsPage1888364a91014c90B2c5C905d1b1e848" header={<SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header0c84c05bD2e14503863050e95ea65ed2" title="Join Us" />} {...props} />
  );
}

// id: "d6ce2573-3d31-465b-98e7-86ff0bc26e7d"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsD6ce25733d31465b98e786ff0bc26e7d(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsD6ce25733d31465b98e786ff0bc26e7d", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "3809735c-f329-49b8-ab32-7266eb10a78b"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero3809735cF32949b8Ab327266eb10a78b(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero3809735cF32949b8Ab327266eb10a78b hero`}>
      <div className="title">
        DEMO-3
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button0c9c2888E076417a8c9d2c38b0c04945", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "b8d663c6-e4a6-4b7f-bc3e-246122c41295"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "572736ea-54f5-4cac-b2ee-7168b785432a"
export function FooterB8d663c6E4a64b7fBc3e246122c41295(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterB8d663c6E4a64b7fBc3e246122c41295" {...props} />
  );
}

// id: "f102f6e4-b1cc-4d0b-a809-15bbaec5082e"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "ebabc526-d242-41e3-bf97-fd7f1f9ac5ef"
export const NavMenuSlugF102f6e4B1cc4d0bA80915bbaec5082e = "marketing-primary-nav";

// id: "fce22f97-3416-4ba4-94e8-6508babea0dd"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "93d89761-fd2c-4f67-ae40-0ae949137ee6"
export function FooterFce22f9734164ba494e86508babea0dd(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterFce22f9734164ba494e86508babea0dd" {...props} />
  );
}

// id: "fa60bcee-fcf8-4e07-b915-fc72975ca1b4"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultFa60bceeFcf84e07B915Fc72975ca1b4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultFa60bceeFcf84e07B915Fc72975ca1b4 page`}>
      {props["header"] || <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header0385a3f35ba44f1393f1A98301f051ae" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterBedbc86aFb8841ea9c3aF3a789f94c76" {...props} />}
    </div>
  );
}

// id: "70f8e994-3af5-4e20-956b-7fb1d4b865a1"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href70f8e9943af54e20956b7fb1d4b865a1 = "/";

// id: "2aaf678e-6779-463b-ba17-aa3640545874"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages2aaf678e6779463bBa17Aa3640545874(props: any) {
  return (
    <SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90 parentTag="SignupPages2aaf678e6779463bBa17Aa3640545874" header={<SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderF3ccf19c1d36407d9b6c47bc6fdcbef4" title="Join Us" />} {...props} />
  );
}

// id: "058131ca-efa5-480c-8153-059b398316be"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage058131caEfa5480c8153059b398316be(props: any) {
  return (
    <LoginPage572736ea54f54cacB2ee7168b785432a parentTag="PasswordResetPage058131caEfa5480c8153059b398316be" {...props} />
  );
}

// id: "4cf6d9e2-e5df-4f6b-bc47-b7158e1b6daa"
// title: ""
// type: :text
// key: "alt"
// parent_id: "03e1bb78-656f-4c0e-aa47-4393738a0548"
export const Alt4cf6d9e2E5df4f6bBc47B7158e1b6daa = "logo";

// id: "0385a3f3-5ba4-4f13-93f1-a98301f051ae"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fa60bcee-fcf8-4e07-b915-fc72975ca1b4"
export function Header0385a3f35ba44f1393f1A98301f051ae(props: any) {
  return (
    <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header0385a3f35ba44f1393f1A98301f051ae" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0c9c2888-e076-417a-8c9d-2c38b0c04945"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "3809735c-f329-49b8-ab32-7266eb10a78b"
export function Button0c9c2888E076417a8c9d2c38b0c04945(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button0c9c2888E076417a8c9d2c38b0c04945", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "1bb1b3b9-8ea8-46d1-be70-2179f211bb45"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
export function Footer1bb1b3b98ea846d1Be702179f211bb45(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="Footer1bb1b3b98ea846d1Be702179f211bb45" {...props} />
  );
}

// id: "f3ccf19c-1d36-407d-9b6c-47bc6fdcbef4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2aaf678e-6779-463b-ba17-aa3640545874"
export function HeaderF3ccf19c1d36407d9b6c47bc6fdcbef4(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderF3ccf19c1d36407d9b6c47bc6fdcbef4" title="Join Us" {...props} />
  );
}

// id: "5bd7c9e3-04f5-45e1-84da-bada900b6a06"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "e06d6f13-c7bf-429a-b0fc-09e1f5815663"
export const PostSlug5bd7c9e304f545e184daBada900b6a06 = "about-page-posts";

// id: "e3541341-e236-4230-8bc6-a2da0602a766"
// title: ""
// type: :text
// key: "href"
// parent_id: "0c9c2888-e076-417a-8c9d-2c38b0c04945"
export const HrefE3541341E23642308bc6A2da0602a766 = "/join";

// id: "ca7eae82-5ce1-4a60-a885-3307669376d4"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e3a52802-5fac-4e65-834e-bfa18ac8c950"
export const NavMenuSlugCa7eae825ce14a60A8853307669376d4 = "footer-nav";

// id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage068906cf3a1b4d818dbdEb06401c8c2d(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage068906cf3a1b4d818dbdEb06401c8c2d page`}>
      {props["header"] || <HomeHeaderC99e2b5bFf1845379fb123abdc816845 parentTag="Header0703d4d0429d4f9d91796281ae103378" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack35f881d7364e41798076C64c292214ff", parentTag)} {...props} />}
      {props["body"] || <MarketingBody01d485a44c6a4ae7Bcf42b842f411e59 parentTag="Body8ad452bd655a40d2A2cc3c66acd665a5" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <Quote9f76fdecE24849729d0b6813522afa04 />}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="Footer1bb1b3b98ea846d1Be702179f211bb45" {...props} />}
    </div>
  );
}

// id: "572736ea-54f5-4cac-b2ee-7168b785432a"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage572736ea54f54cacB2ee7168b785432a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header44f38bb2C34344f2B426087b6798ca78" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterB8d663c6E4a64b7fBc3e246122c41295" {...props} />}
    </>
  );
}

// id: "71ac9718-62f2-47eb-9c33-e68df9eb19a7"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages71ac971862f247eb9c33E68df9eb19a7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages71ac971862f247eb9c33E68df9eb19a7`}>
      {props["header"] || <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header6bf5de4aBb7c42788f68Ca8943251a4f" {...props} />}
      {props.children}
    </div>
  );
}

// id: "13def918-a3d0-407b-af81-7d417ae76d0d"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "659f1fca-0286-40c7-a44d-c57baa9ab102"
export function LinkedLogo13def918A3d0407bAf817d417ae76d0d(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo13def918A3d0407bAf817d417ae76d0d", parentTag)} label={<DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="Label8b58be987fab46edB2283deadca33228" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "9f76fdec-e248-4972-9d0b-6813522afa04"
// title: ""
// type: :html
// key: "quote"
// parent_id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
export function Quote9f76fdecE24849729d0b6813522afa04(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Quote9f76fdecE24849729d0b6813522afa04 PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qLgFtj0sve8UYvTr_regular.png" className="image opacity" />
      <div className="TextContent">
        <div className="text-inner">
          Our mission is to change the world by promoting healthy intimacy through community and education.
        </div>
      </div>
    </div>
  );
}

// id: "fcc41b10-3c88-4554-8bc2-7e97c9367f7c"
// title: ""
// type: :text
// key: "title"
// parent_id: "ebabc526-d242-41e3-bf97-fd7f1f9ac5ef"
export const TitleFcc41b103c8845548bc27e97c9367f7c = null;

// id: "35f881d7-364e-4179-8076-c64c292214ff"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
export function WelcomeBack35f881d7364e41798076C64c292214ff(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack35f881d7364e41798076C64c292214ff", parentTag)} {...props} />
  );
}

// id: "bedbc86a-fb88-41ea-9c3a-f3a789f94c76"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "fa60bcee-fcf8-4e07-b915-fc72975ca1b4"
export function FooterBedbc86aFb8841ea9c3aF3a789f94c76(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterBedbc86aFb8841ea9c3aF3a789f94c76" {...props} />
  );
}

// id: "c398024c-d4cf-40df-9f70-d349866d07f3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "60d4b1c0-e46b-4f82-b758-0d4bf9d01a90"
export function HeaderC398024cD4cf40df9f70D349866d07f3(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderC398024cD4cf40df9f70D349866d07f3" title="Secondary Page" {...props} />
  );
}

// id: "c29b44bb-6a13-408b-a736-1c5f86a1149d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2e9c4620-bef6-40ab-94bd-ce53c539c986"
export function FooterC29b44bb6a13408bA7361c5f86a1149d(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterC29b44bb6a13408bA7361c5f86a1149d" {...props} />
  );
}

// id: "f61e846e-ea70-4f88-9c58-535187028eb4"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5f3146ef-c985-47fe-8bd0-dbc687db3169"
export const NavMenuSlugF61e846eEa704f889c58535187028eb4 = "members-primary-nav";

// id: "8b58be98-7fab-46ed-b228-3deadca33228"
// title: ""
// type: :reference
// key: "label"
// parent_id: "13def918-a3d0-407b-af81-7d417ae76d0d"
export function Label8b58be987fab46edB2283deadca33228(props: any) {
  return (
    <DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="Label8b58be987fab46edB2283deadca33228" {...props} />
  );
}

// id: "c7dab0ba-bf7a-4208-8da0-373d11a22b9b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageC7dab0baBf7a42088da0373d11a22b9b(props: any) {
  return (
    <SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90 parentTag="ResourcesPageC7dab0baBf7a42088da0373d11a22b9b" header={<SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header093e5a97Dc214424BcbdA69740c9a5aa" title="Resources" />} {...props} />
  );
}

// id: "01d485a4-4c6a-4ae7-bcf4-2b842f411e59"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody01d485a44c6a4ae7Bcf42b842f411e59(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody01d485a44c6a4ae7Bcf42b842f411e59 page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCards8d2ecd3dF3584cccB7111da6e63bdcf0", parentTag)} {...props} />}
    </div>
  );
}

// id: "b46d9204-27b0-4de4-81eb-ed7bd6620dd2"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderB46d920427b04de481ebEd7bd6620dd2(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderB46d920427b04de481ebEd7bd6620dd2 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoE0a3de4a84f1416d93265808271b64d8", parentTag)} label={<DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="LabelE7432bbf0d7347e3B30a3e22e3252e70" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationD993915997bb45cf9aad3e5f63bb291a", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "093e5a97-dc21-4424-bcbd-a69740c9a5aa"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c7dab0ba-bf7a-4208-8da0-373d11a22b9b"
export function Header093e5a97Dc214424BcbdA69740c9a5aa(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header093e5a97Dc214424BcbdA69740c9a5aa" title="Resources" {...props} />
  );
}

// id: "0703d4d0-429d-4f9d-9179-6281ae103378"
// title: ""
// type: :reference
// key: "header"
// parent_id: "068906cf-3a1b-4d81-8dbd-eb06401c8c2d"
export function Header0703d4d0429d4f9d91796281ae103378(props: any) {
  return (
    <HomeHeaderC99e2b5bFf1845379fb123abdc816845 parentTag="Header0703d4d0429d4f9d91796281ae103378" {...props} />
  );
}

// id: "896e73a1-8dfa-48ea-9a9e-3c3f2f333c83"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTopDc29acea46c74849B6d775f98d5b3619" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero862917f319a14d9eA4624c47415fa3d6 parentTag="Hero6d09aaaa4682452b86f924c242b1612c" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "e06d6f13-c7bf-429a-b0fc-09e1f5815663"
// title: ""
// type: :reference
// key: "body"
// parent_id: "93d89761-fd2c-4f67-ae40-0ae949137ee6"
export function BodyE06d6f13C7bf429aB0fc09e1f5815663(props: any) {
  return (
    <MarketingBody01d485a44c6a4ae7Bcf42b842f411e59 parentTag="BodyE06d6f13C7bf429aB0fc09e1f5815663" postSlug="about-page-posts" {...props} />
  );
}

// id: "2f225de3-d6be-47a1-a53c-97bb3c97ee60"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "2e9c4620-bef6-40ab-94bd-ce53c539c986"
export function SecondaryNav2f225de3D6be47a1A53c97bb3c97ee60(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav2f225de3D6be47a1A53c97bb3c97ee60", parentTag)} {...props} />
  );
}

// id: "3e24c779-5d38-4dc4-83f5-b197e2ecde46"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function SocialLinks3e24c7795d384dc483f5B197e2ecde46(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks3e24c7795d384dc483f5B197e2ecde46 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "003a08a8-56c2-466d-9c91-b918e9e9001b"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function Copyright003a08a856c2466d9c91B918e9e9001b(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright003a08a856c2466d9c91B918e9e9001b", parentTag)} {...props} />
  );
}

// id: "5f3146ef-c985-47fe-8bd0-dbc687db3169"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "60c625f0-4ee5-44a3-bad8-a4fe9f1721cd"
export function HeaderTop5f3146efC98547fe8bd0Dbc687db3169(props: any) {
  return (
    <DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTop5f3146efC98547fe8bd0Dbc687db3169" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "862917f3-19a1-4d9e-a462-4c47415fa3d6"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero862917f319a14d9eA4624c47415fa3d6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero862917f319a14d9eA4624c47415fa3d6 hero`}>
      <div className="title">
        {props["title"] || Title0777233bD37b4468973051c30787f4f0}
      </div>
    </div>
  );
}

// id: "20681489-1398-4144-8389-086b3cf3700b"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "c99e2b5b-ff18-4537-9fb1-23abdc816845"
export function Hero20681489139841448389086b3cf3700b(props: any) {
  return (
    <HomePageHero3809735cF32949b8Ab327266eb10a78b parentTag="Hero20681489139841448389086b3cf3700b" {...props} />
  );
}

// id: "7baf3e20-e726-4ebf-bd9d-c76f8c269ea6"
// title: ""
// type: :text
// key: "title"
// parent_id: "896e73a1-8dfa-48ea-9a9e-3c3f2f333c83"
export const Title7baf3e20E7264ebfBd9dC76f8c269ea6 = "Secondary Marketing Title 1";

// id: "484dff56-3f1a-4d33-a6a7-5242df08d559"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function Groupflow484dff563f1a4d33A6a75242df08d559(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow484dff563f1a4d33A6a75242df08d559", parentTag)} {...props} />
  );
}

// id: "dd983a22-077f-4e7b-9e62-43be3a38ccbf"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "07d40535-c3f4-49d5-8a3a-481db3fd6e78"
export function FooterDd983a22077f4e7b9e6243be3a38ccbf(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterDd983a22077f4e7b9e6243be3a38ccbf" {...props} />
  );
}

// id: "0777233b-d37b-4468-9730-51c30787f4f0"
// title: ""
// type: :text
// key: "title"
// parent_id: "862917f3-19a1-4d9e-a462-4c47415fa3d6"
export const Title0777233bD37b4468973051c30787f4f0 = "Default Title";

// id: "59a9b412-32c8-48fd-9d45-6ebcced992e2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "07d40535-c3f4-49d5-8a3a-481db3fd6e78"
export function Header59a9b41232c848fd9d456ebcced992e2(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header59a9b41232c848fd9d456ebcced992e2" title="Public Events" {...props} />
  );
}

// id: "ab6b2a3c-1439-457a-bd3b-b57171902c1d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "93d89761-fd2c-4f67-ae40-0ae949137ee6"
export function HeaderAb6b2a3c1439457aBd3bB57171902c1d(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="HeaderAb6b2a3c1439457aBd3bB57171902c1d" title="About Us" {...props} />
  );
}

// id: "3e7b4ac4-af84-4a68-bfde-562c834e49ef"
// title: ""
// type: :text
// key: "label"
// parent_id: "0c9c2888-e076-417a-8c9d-2c38b0c04945"
export const Label3e7b4ac4Af844a68Bfde562c834e49ef = "Join Us";

// id: "45491d23-f9c8-4dd1-b77a-cb7538c7a055"
// title: ""
// type: :text
// key: "title"
// parent_id: "c398024c-d4cf-40df-9f70-d349866d07f3"
export const Title45491d23F9c84dd1B77aCb7538c7a055 = "Secondary Page";

// id: "729b17da-9f53-4165-8c5d-c78e1cd35ef8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2e9c4620-bef6-40ab-94bd-ce53c539c986"
export function Header729b17da9f5341658c5dC78e1cd35ef8(props: any) {
  return (
    <MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd parentTag="Header729b17da9f5341658c5dC78e1cd35ef8" {...props} />
  );
}

// id: "dc29acea-46c7-4849-b6d7-75f98d5b3619"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "896e73a1-8dfa-48ea-9a9e-3c3f2f333c83"
export function HeaderTopDc29acea46c74849B6d775f98d5b3619(props: any) {
  return (
    <DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTopDc29acea46c74849B6d775f98d5b3619" {...props} />
  );
}

// id: "75b27ba0-49b8-42b4-9e69-26af5079bcce"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c99e2b5b-ff18-4537-9fb1-23abdc816845"
export function HeaderTop75b27ba049b842b49e6926af5079bcce(props: any) {
  return (
    <DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102 parentTag="HeaderTop75b27ba049b842b49e6926af5079bcce" {...props} />
  );
}

// id: "e312fee7-9e1b-4f91-8d0f-6a888e7a015b"
// title: ""
// type: :text
// key: "title"
// parent_id: "093e5a97-dc21-4424-bcbd-a69740c9a5aa"
export const TitleE312fee79e1b4f918d0f6a888e7a015b = "Resources";

// id: "b51eaeed-ad96-4098-9547-279bcdf2a606"
// title: ""
// type: :text
// key: "class"
// parent_id: "13def918-a3d0-407b-af81-7d417ae76d0d"
export const ClassB51eaeedAd9640989547279bcdf2a606 = "linked-logo";

// id: "376a2b76-8b75-4397-ba52-ef1c4bba443e"
// title: ""
// type: :text
// key: "title"
// parent_id: "ab6b2a3c-1439-457a-bd3b-b57171902c1d"
export const Title376a2b768b754397Ba52Ef1c4bba443e = "About Us";

// id: "d9939159-97bb-45cf-9aad-3e5f63bb291a"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "b46d9204-27b0-4de4-81eb-ed7bd6620dd2"
export function NavigationD993915997bb45cf9aad3e5f63bb291a(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationD993915997bb45cf9aad3e5f63bb291a", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "e3a52802-5fac-4e65-834e-bfa18ac8c950"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "26d19b92-19fb-44c3-8318-ee8d39aabc4e"
export function LinksE3a528025fac4e65834eBfa18ac8c950(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksE3a528025fac4e65834eBfa18ac8c950", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "4f4e1138-e1c2-4530-9987-d0bd2a2c16b8"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "659f1fca-0286-40c7-a44d-c57baa9ab102"
export function Navigation4f4e1138E1c245309987D0bd2a2c16b8(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation4f4e1138E1c245309987D0bd2a2c16b8", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "36e889d1-6844-4386-88ee-60a5f707fddd"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0385a3f3-5ba4-4f13-93f1-a98301f051ae"
export const NavMenuSlug36e889d16844438688ee60a5f707fddd = "members-primary-nav";

// id: "bd68187e-27c3-4db9-8541-a014464e1233"
// title: ""
// type: :text
// key: "title"
// parent_id: "0c84c05b-d2e1-4503-8630-50e95ea65ed2"
export const TitleBd68187e27c34db98541A014464e1233 = "Join Us";

// id: "e7432bbf-0d73-47e3-b30a-3e22e3252e70"
// title: ""
// type: :reference
// key: "label"
// parent_id: "e0a3de4a-84f1-416d-9326-5808271b64d8"
export function LabelE7432bbf0d7347e3B30a3e22e3252e70(props: any) {
  return (
    <DefaultLogo03e1bb78656f4c0eAa474393738a0548 parentTag="LabelE7432bbf0d7347e3B30a3e22e3252e70" {...props} />
  );
}

// id: "fd987857-a33a-46ac-b69a-1296f0cf0bb8"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "60d4b1c0-e46b-4f82-b758-0d4bf9d01a90"
export function FooterFd987857A33a46acB69a1296f0cf0bb8(props: any) {
  return (
    <DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e parentTag="FooterFd987857A33a46acB69a1296f0cf0bb8" {...props} />
  );
}

// id: "0c84c05b-d2e1-4503-8630-50e95ea65ed2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "1888364a-9101-4c90-b2c5-c905d1b1e848"
export function Header0c84c05bD2e14503863050e95ea65ed2(props: any) {
  return (
    <SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83 parentTag="Header0c84c05bD2e14503863050e95ea65ed2" title="Join Us" {...props} />
  );
}

// id: "4a1b2b7c-b88b-4d00-aa49-b72dfce099f8"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "8ad452bd-655a-40d2-a2cc-3c66acd665a5"
export const PostSlug4a1b2b7cB88b4d00Aa49B72dfce099f8 = "home-page-posts2";

// id: "3ce43e1b-40e0-4cc1-92cd-e11cdeec10f5"
// title: ""
// type: :text
// key: "class"
// parent_id: "4f4e1138-e1c2-4530-9987-d0bd2a2c16b8"
export const Class3ce43e1b40e04cc192cdE11cdeec10f5 = "navigation";

// id: "bcfcd71f-dab4-4a2b-ba2a-8983863ca379"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "4f4e1138-e1c2-4530-9987-d0bd2a2c16b8"
export const NavMenuSlugBcfcd71fDab44a2bBa2a8983863ca379 = "marketing-primary-nav";

// id: "3bc8165e-1e3a-4618-a5bb-6a2ab125765d"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "44f38bb2-c343-44f2-b426-087b6798ca78"
export const Title3bc8165e1e3a4618A5bb6a2ab125765d = "Login";

// id: "d43bb08d-d242-463a-bbad-6495f2f85675"
// title: ""
// type: :text
// key: "title"
// parent_id: "f3ccf19c-1d36-407d-9b6c-47bc6fdcbef4"
export const TitleD43bb08dD242463aBbad6495f2f85675 = "Join Us";

// id: "fe105200-ef43-4405-9e69-d2b484ab0d13"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d9939159-97bb-45cf-9aad-3e5f63bb291a"
export const NavMenuSlugFe105200Ef4344059e69D2b484ab0d13 = "members-primary-nav";

// id: "4d44856c-1614-462b-91c6-d9455e6bff52"
// title: ""
// type: :text
// key: "title"
// parent_id: "6d09aaaa-4682-452b-86f9-24c242b1612c"
export const Title4d44856c1614462b91c6D9455e6bff52 = "Secondary Marketing Title 2";

// id: "99a6cdc2-9315-4dc5-b428-ba9cee332a4f"
// title: ""
// type: :text
// key: "title"
// parent_id: "59a9b412-32c8-48fd-9d45-6ebcced992e2"
export const Title99a6cdc293154dc5B428Ba9cee332a4f = "Public Events";

// id: "bbf2ec4f-50a6-4a1f-b74b-61f4a2719bcd"
// title: ""
// type: :text
// key: "url"
// parent_id: "13def918-a3d0-407b-af81-7d417ae76d0d"
export const UrlBbf2ec4f50a64a1fB74b61f4a2719bcd = "/";

const Components = {
  HomeHeaderC99e2b5bFf1845379fb123abdc816845,
  AboutUsPage93d89761Fd2c4f67Ae400ae949137ee6,
  PageQuoteFb9d6a184a1a47c295de285b3e110a34,
  PublicEventsPage07d40535C3f449d58a3a481db3fd6e78,
  Hero6d09aaaa4682452b86f924c242b1612c,
  SocialLinksB5a623c4D82c46d5Bec4743dcf637dfc,
  Logo5c37a761Bc5f4525Ab5aAa561fab43a1,
  AdminPagesEd0a097a564d4f48871150f43d1511e3,
  DefaultHeaderEbabc526D24241e3Bf97Fd7f1f9ac5ef,
  FooterC5b50c7cFd8f44cc8c5f9728320ada40,
  DefaultLogo03e1bb78656f4c0eAa474393738a0548,
  SecondaryPage60d4b1c0E46b4f82B7580d4bf9d01a90,
  MemberPages2e9c4620Bef640ab94bdCe53c539c986,
  DefaultHeaderTop659f1fca028640c7A44dC57baa9ab102,
  LinkedLogoE0a3de4a84f1416d93265808271b64d8,
  ButtonClass951f14244ee34ae896ed02b10daf613a,
  Header6bf5de4aBb7c42788f68Ca8943251a4f,
  Header44f38bb2C34344f2B426087b6798ca78,
  BackgroundImage97a19c3858364028A1d67c6138ee951a,
  Body8ad452bd655a40d2A2cc3c66acd665a5,
  MarketingCards8d2ecd3dF3584cccB7111da6e63bdcf0,
  MembersAreaHeader60c625f04ee544a3Bad8A4fe9f1721cd,
  PostSlug28d90ba7Bea44ae6B6a289c6017d6b37,
  DefaultFooter26d19b9219fb44c38318Ee8d39aabc4e,
  Label7fc18d0c536b4a8b89c3958e97094976,
  JoinUsPage1888364a91014c90B2c5C905d1b1e848,
  MarketingCardsD6ce25733d31465b98e786ff0bc26e7d,
  HomePageHero3809735cF32949b8Ab327266eb10a78b,
  FooterB8d663c6E4a64b7fBc3e246122c41295,
  NavMenuSlugF102f6e4B1cc4d0bA80915bbaec5082e,
  FooterFce22f9734164ba494e86508babea0dd,
  MembersAreaDefaultFa60bceeFcf84e07B915Fc72975ca1b4,
  Href70f8e9943af54e20956b7fb1d4b865a1,
  SignupPages2aaf678e6779463bBa17Aa3640545874,
  PasswordResetPage058131caEfa5480c8153059b398316be,
  Alt4cf6d9e2E5df4f6bBc47B7158e1b6daa,
  Header0385a3f35ba44f1393f1A98301f051ae,
  Button0c9c2888E076417a8c9d2c38b0c04945,
  Footer1bb1b3b98ea846d1Be702179f211bb45,
  HeaderF3ccf19c1d36407d9b6c47bc6fdcbef4,
  PostSlug5bd7c9e304f545e184daBada900b6a06,
  HrefE3541341E23642308bc6A2da0602a766,
  NavMenuSlugCa7eae825ce14a60A8853307669376d4,
  HomePage068906cf3a1b4d818dbdEb06401c8c2d,
  LoginPage572736ea54f54cacB2ee7168b785432a,
  MessagePages71ac971862f247eb9c33E68df9eb19a7,
  LinkedLogo13def918A3d0407bAf817d417ae76d0d,
  Quote9f76fdecE24849729d0b6813522afa04,
  TitleFcc41b103c8845548bc27e97c9367f7c,
  WelcomeBack35f881d7364e41798076C64c292214ff,
  FooterBedbc86aFb8841ea9c3aF3a789f94c76,
  HeaderC398024cD4cf40df9f70D349866d07f3,
  FooterC29b44bb6a13408bA7361c5f86a1149d,
  NavMenuSlugF61e846eEa704f889c58535187028eb4,
  Label8b58be987fab46edB2283deadca33228,
  ResourcesPageC7dab0baBf7a42088da0373d11a22b9b,
  MarketingBody01d485a44c6a4ae7Bcf42b842f411e59,
  ShortHeaderB46d920427b04de481ebEd7bd6620dd2,
  Header093e5a97Dc214424BcbdA69740c9a5aa,
  Header0703d4d0429d4f9d91796281ae103378,
  SecondaryMarketingHeader896e73a18dfa48ea9a9e3c3f2f333c83,
  BodyE06d6f13C7bf429aB0fc09e1f5815663,
  SecondaryNav2f225de3D6be47a1A53c97bb3c97ee60,
  SocialLinks3e24c7795d384dc483f5B197e2ecde46,
  Copyright003a08a856c2466d9c91B918e9e9001b,
  HeaderTop5f3146efC98547fe8bd0Dbc687db3169,
  SecondaryMarketingHero862917f319a14d9eA4624c47415fa3d6,
  Hero20681489139841448389086b3cf3700b,
  Title7baf3e20E7264ebfBd9dC76f8c269ea6,
  Groupflow484dff563f1a4d33A6a75242df08d559,
  FooterDd983a22077f4e7b9e6243be3a38ccbf,
  Title0777233bD37b4468973051c30787f4f0,
  Header59a9b41232c848fd9d456ebcced992e2,
  HeaderAb6b2a3c1439457aBd3bB57171902c1d,
  Label3e7b4ac4Af844a68Bfde562c834e49ef,
  Title45491d23F9c84dd1B77aCb7538c7a055,
  Header729b17da9f5341658c5dC78e1cd35ef8,
  HeaderTopDc29acea46c74849B6d775f98d5b3619,
  HeaderTop75b27ba049b842b49e6926af5079bcce,
  TitleE312fee79e1b4f918d0f6a888e7a015b,
  ClassB51eaeedAd9640989547279bcdf2a606,
  Title376a2b768b754397Ba52Ef1c4bba443e,
  NavigationD993915997bb45cf9aad3e5f63bb291a,
  LinksE3a528025fac4e65834eBfa18ac8c950,
  Navigation4f4e1138E1c245309987D0bd2a2c16b8,
  NavMenuSlug36e889d16844438688ee60a5f707fddd,
  TitleBd68187e27c34db98541A014464e1233,
  LabelE7432bbf0d7347e3B30a3e22e3252e70,
  FooterFd987857A33a46acB69a1296f0cf0bb8,
  Header0c84c05bD2e14503863050e95ea65ed2,
  PostSlug4a1b2b7cB88b4d00Aa49B72dfce099f8,
  Class3ce43e1b40e04cc192cdE11cdeec10f5,
  NavMenuSlugBcfcd71fDab44a2bBa2a8983863ca379,
  Title3bc8165e1e3a4618A5bb6a2ab125765d,
  TitleD43bb08dD242463aBbad6495f2f85675,
  NavMenuSlugFe105200Ef4344059e69D2b484ab0d13,
  Title4d44856c1614462b91c6D9455e6bff52,
  Title99a6cdc293154dc5B428Ba9cee332a4f,
  UrlBbf2ec4f50a64a1fB74b61f4a2719bcd
}

export default Components;