const ROUTES = {
  "/": {
    "name": "HomePage068906cf3a1b4d818dbdEb06401c8c2d",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesEd0a097a564d4f48871150f43d1511e3",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage93d89761Fd2c4f67Ae400ae949137ee6",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPage07d40535C3f449d58a3a481db3fd6e78",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage1888364a91014c90B2c5C905d1b1e848",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage572736ea54f54cacB2ee7168b785432a",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPages2e9c4620Bef640ab94bdCe53c539c986",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages71ac971862f247eb9c33E68df9eb19a7",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage058131caEfa5480c8153059b398316be",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPageC7dab0baBf7a42088da0373d11a22b9b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages2aaf678e6779463bBa17Aa3640545874",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;